<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
              <b-row>
                <b-col md="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="seed_infos_id"
                  >
                      <template v-slot:label>
                          {{$t('certify_form.lot_no')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                      id="lot_no"
                      v-model="data.seed_infos_id"
                      :options="lotNumberList"
                      @change="getSeedInfos"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="''" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-overlay :show="load">
              <b-row v-if="loads">
                  <b-col md=12 class="text-right">
                    <b-button variant="primary" @click="pdfExport" class="mr-2 mb-2 btn-sm float-right">
                      <i class="fa fa-print"></i> {{  $t('globalTrans.print') }}
                    </b-button>
                  </b-col>
                  <b-col md="12">
                      <div style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('research_results.seed_test_result')}}</h5></div>
                      <b-table-simple striped bordered small hover>
                          <tbody>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.lab_name')}}</b-td>
                          <b-td>{{ $i18n.locale === 'en' ? data.lab_office_name : data.lab_office_name_bn }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.seed_source_name')}}</b-td>
                          <b-td>{{ $i18n.locale === 'en' ? data.seed_source_name : data.seed_source_name_bn}} </b-td>
                          </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('seed_report.seed_name')}}</b-td>
                          <b-td>{{ data.seed_name_id == 6 ? data.seed_name_others : ($i18n.locale === 'en' ? data.seed_name : data.seed_name_bn) }}</b-td>
                          <b-td style="font-weight:bold">{{$t('tag_print.seed_variety_name')}}</b-td>
                          <b-td>{{ data.seed_name_id == 6 ? data.seed_variety_others_name : ($i18n.locale === 'en' ? data.seed_variety_name : data.seed_variety_name_bn) }}</b-td>
                          </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('seed_report.seed_class')}}</b-td>
                          <b-td>{{ data.seed_class_name }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.organization_address')}}</b-td>
                          <b-td>{{ data.organization_address !== null && data.organization_address !== undefined ? data.organization_address.replace(/null|undefined/g, '') : '' }}</b-td>
                          </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.lot_no')}}</b-td>
                          <b-td>{{ data.lot_number }}</b-td>
                          <!-- {{ data.lot_no }}  -->
                          <b-td style="font-weight:bold">{{$t('research_results.sample_collection_date')}}</b-td>
                          <b-td>{{ data.sample_collection_date }}</b-td>
                          </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.seed_examination_start_date')}}</b-td>
                          <b-td>{{ data.seed_exam_start_date }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.seed_examination_end_date')}}</b-td>
                          <b-td>{{ data.seed_exam_end_date }}</b-td>
                          </b-tr>
                          </tbody>
                          </b-table-simple>
                          <b-table-simple striped bordered small hover>
                          <tbody>
                          <b-tr><b-td style="font-weight:bold" colspan="6"><h5 style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);" class="text-white text-center">{{$t('research_results.test_name_seed_quality')}}</h5></b-td></b-tr>
                          <b-tr><b-td style="font-weight:bold" colspan="6"><h5 style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);" class="text-white text-center">{{$t('research_results.purity_test')}}</h5></b-td></b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.pure_seed_result')}}</b-td><b-td>{{ data.pure_seed_result }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.pure_seed_quality')}}</b-td><b-td>{{ data.pure_seed_quality }}</b-td>
                          </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.inert_substance_result')}}</b-td><b-td>{{ data.inert_substance_result }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.inert_substance_quality')}}</b-td><b-td>{{ data.inert_substance_quality }}</b-td>
                        </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.others_seed_result')}}</b-td><b-td>{{ data.others_seed_result }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.others_seed_quality')}}</b-td><b-td>{{ data.others_seed_quality }}</b-td>
                        </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.seed_of_other_crops_result')}}</b-td><b-td>{{ data.seed_of_other_crops_result }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.seed_of_other_crops_quality')}}</b-td><b-td>{{ data.seed_of_other_crops_quality }}</b-td>
                        </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.weed_seeds_result')}}</b-td><b-td>{{ data.weed_seed_result }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.weed_seeds_quality')}}</b-td><b-td>{{ data.weed_seed_quality }}</b-td>
                        </b-tr>
                          <b-tr>
                            <b-td style="font-weight:bold">{{$t('research_results.result')}}</b-td><b-td>{{ data.tag_status === 1 ? $t('research_results.satisfied') : data.tag_status === 2 ? $t('research_results.unsatisfied') : '' }} </b-td>
                            <b-td style="font-weight:bold">{{$t('research_results.comments')}}</b-td><b-td>{{ data.purity_test === 1 ? $t('tag.approve') : data.purity_test === 2 ? $t('tag.reject') : '' }}</b-td>
                          </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold" colspan="6"><h5 style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);" class="text-white text-center">{{$t('research_results.germination_test')}}</h5></b-td>
                          </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.normal_seed_result')}}</b-td><b-td>{{ data.normal_seed_result }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.normal_seed_quality')}}</b-td><b-td>{{ data.normal_seed_quality }}</b-td>
                          </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.unusual_seed_result')}}</b-td><b-td>{{ data.unusual_seed_result }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.unusual_seed_quality')}}</b-td><b-td>{{ data.unusual_seed_quality }}</b-td>
                        </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.fresh_seed_result')}}</b-td><b-td>{{ data.fresh_seed_result }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.fresh_seed_quality')}}</b-td><b-td>{{ data.fresh_seed_quality }}</b-td>
                        </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.hard_seeds_result')}}</b-td><b-td>{{ data.hard_seed_result }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.hard_seeds_quality')}}</b-td><b-td>{{ data.hard_seed_quality }}</b-td>
                        </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.dead_seed_result')}}</b-td><b-td>{{ data.dead_seed_result }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.dead_seed_quality')}}</b-td><b-td>{{ data.dead_seed_quality }}</b-td>
                        </b-tr>
                          <b-tr>
                            <b-td style="font-weight:bold">{{$t('research_results.result')}}</b-td><b-td>{{ data.tag_status === 1 ? $t('research_results.satisfied') : data.tag_status === 2 ? $t('research_results.unsatisfied') : '' }} </b-td>
                            <b-td style="font-weight:bold">{{$t('research_results.comments')}}</b-td><b-td>{{ data.germination_test === 1 ? $t('tag.approve') : data.germination_test === 2 ? $t('tag.reject') : '' }}</b-td>
                          </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold" colspan="6"><h5 style="padding: 10px; font-size: 14px; background-color: rgb(51, 121, 130);" class="text-white text-center">{{$t('research_results.humidity_test')}}</h5></b-td>
                          </b-tr>
                          <b-tr>
                          <b-td style="font-weight:bold">{{$t('research_results.humidity_test_result')}}</b-td><b-td>{{ data.humidity_test_result }}</b-td>
                          <b-td style="font-weight:bold">{{$t('research_results.humidity_test_quality')}}</b-td><b-td>{{ data.humidity_test_quality }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td style="font-weight:bold">{{$t('research_results.result')}}</b-td><b-td>{{ data.tag_status === 1 ? $t('research_results.satisfied') : data.tag_status === 2 ? $t('research_results.unsatisfied') : '' }} </b-td>
                            <b-td style="font-weight:bold">{{$t('research_results.comments')}}</b-td><b-td>{{ data.moisture_test === 1 ? $t('tag.approve') : data.moisture_test === 2 ? $t('tag.reject') : '' }}</b-td>
                          </b-tr>
                          </tbody>
                      </b-table-simple>
                      <b-row v-if="data.user_name || data.lab_user_name" style="margin-top: 10% !important">
                            <b-col lg="4" class="text-left">
                                <!--<hr style="margin-bottom: 0rem !important;border-top: 1px solid #000 !important">-->
                                <span v-if="data.lab_user_name">. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .</span>
                                <br>
                                <span class="font-weight-bold">{{ $i18n.locale === 'en' ? data.lab_user_name : data.lab_user_name_bn }}</span><br>
                                <span class="font-weight-bold">{{ $i18n.locale === 'en' ? data.lab_designation_name : data.lab_designation_name_bn }}</span><br>
                                <span class="font-weight-bold">{{ $i18n.locale === 'en' ? data.lab_office_name : data.lab_office_name_bn }}</span><br>
                            </b-col>
                            <b-col lg="4">
                            </b-col>
                            <b-col lg="4" class="text-left">
                                <!--<hr style="margin-bottom: 0rem !important;border-top: 1px solid #000 !important">-->
                                <span v-if="data.user_name">. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .</span>
                                <br>
                                <span class="font-weight-bold">{{ $i18n.locale === 'en' ? data.user_name : data.user_name_bn }}</span><br>
                                <span class="font-weight-bold">{{ $i18n.locale === 'en' ? data.designation_name : data.designation_name_bn }}</span><br>
                                <span class="font-weight-bold">{{ $i18n.locale === 'en' ? data.office_name : data.office_name_bn }}</span><br>
                            </b-col>
                        </b-row>
                  </b-col>
              <b-col md="12" class="col text-right">
                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.close') }}</b-button>
              </b-col>
              </b-row>
              </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { detailsVieweModalApi } from '../../api/routes'
import LabPrint from './lab_print'
export default {
    props: ['item'],
    components: {
    },
    created () {
      this.lotNumberList = this.getLotNumbers()
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            data: [],
            lotNumberListData: [],
            loads: false,
            load: false
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        getSeedInfos () {
            this.loads = true
            this.load = true
            this.loadData(this.data.seed_infos_id)
        },
        getLotNumbers () {
            return this.item.seed_infos.map(org => {
                    return { value: org.id, text: this.$i18n.locale === 'en' ? org.lot_number : org.lot_number }
            })
        },
        pdfExport () {
          const reportTitle = this.$i18n.locale === 'en' ? 'Money Receipt' : 'অর্থ প্রদানের রসিদ'
          LabPrint.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 14, reportTitle, this.data, this)
        },
        Checker (type) {
          return (type === 1) ? this.$t('request_testing_tag_without_field_certification_application.locally_produced') : this.$t('request_testing_tag_without_field_certification_application.imported')
        },
        CheckRegister (type) {
            return (type === 1) ? this.$t('request_testing_tag_without_field_certification_application.registered_status') : this.$t('request_testing_tag_without_field_certification_application.un-registered_status')
        },
        async loadData (id) {
            const result = await RestApi.getData(agriResearchServiceBaseUrl, detailsVieweModalApi + '/' + id)
            if (result.success) {
                this.data = result.data
            }
            this.load = false
        }
    }
}

</script>
<style lang="scss">
    .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
  }
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
