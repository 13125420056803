
// Seed Type Starts
export const labList = '/master-testing-lab/list'
export const labStore = '/master-testing-lab/store'
export const labUpdate = '/master-testing-lab/update'
export const labToggleStatus = '/master-testing-lab/toggle-status'
export const labDestroy = '/master-testing-lab/destroy'
export const testingTagRequestWithoutCetificationSubmited = '/testing-tag-request-without-certification/submitted-index'
export const testingTagRequestWithoutCetificationSample = '/testing-tag-request-without-certification/sample-index'
export const testingTagRequestWithoutCetificationApprove = '/testing-tag-request-without-certification/approve'
export const testingTagRequestWithoutCetificationShow = '/testing-tag-request-without-certification/show'
export const testingTagRequestWithoutCetificationSubmit = '/testing-tag-request-without-certification/submit'
export const sampleResultSubmit = '/sample-test-result-submission-application/store'
export const sampleResultShow = '/sample-test-result-submission-application/show'
export const sampleCollectionSubmit = '/testing-service-management/collection-store'
export const sampleApproveSubmit = '/testing-service-management/approve-store'
export const testingTagDashboardApi = '/testing-service-management/dashboard'
export const testingTagData = '/testing-service-management/index'
export const testingTagDataAfterApprove = '/testing-service-management/test-tag-index'
export const approveApplication = '/testing-service-management/final-index'
export const getLotNos = '/testing-service-management/lot-no'

// User List
export const getUserList = '/user/user-list'
export const sampleRegistrationAndTestingApi = '/testing-service-management/sample-registration-and-testing'
export const sampleRegistrationAndTestingStoreApi = '/testing-service-management/sample-registration-and-testing/store'
// Tag Print
export const tagPrintApi = '/testing-service-management/tag-print'
export const detailsVieweModalApi = '/testing-service-management/print-details'
export const tagPrintModalApi = '/testing-service-management/print'
export const detailsApi = '/testing-service-management/details'

export const ClassWiseReportApi = '/testing-service-management/get-data'
export const SeedTestReportApi = '/testing-service-management/get-seed-test-report'
export const SeedTestedAmountReportApi = '/testing-service-management/get-seed-test-amount-report'
