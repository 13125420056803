<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <div class="tag_wrapper">
              <b-row class="justify-content-md-center">
                <b-col sm="6">
                  <div class="certified_wrapper foundation_wrapper">
                    <div class="tag_header_wrapper">
                      <h1 class="bd_title">গণপ্রজাতন্ত্রী বাংলাদেশ সরকার</h1>
                      <div class="logo_wrapper">
                        <img src="../../../../../assets/images/logo.png" width="60" alt="">
                        <div class="title_wrapper">
                          <h2 class="title">বীজ প্রত্যয়ন এজেন্সী</h2>
                          <p class="title_card"><span>ভিত্তি বীজ</span></p>
                        </div>
                        <img src="../../../../../assets/images/logor.jpg" width="60" alt="">
                      </div>
                    </div>
                    <div class="tag_body_wrapper">
                      <!-- <p class="title">
                        <span class="title_key" style="margin-right: 15px">ট্যাগ নম্বর</span>
                        <span class="title_value" style="border-bottom: none">{{$n(datas.tag_number)}}</span>
                      </p> -->
                      <div class="crop_type_wrapper">
                        <p class="title" style="width: 40%;"><span class="title_key">ফসল</span> <span class="title_value" style="width: 64%;">
                        {{ $i18n.locale==='en' ? datas.seed_name : datas.seed_name_bn }}
                      </span></p>
                        <p class="title" style="width: 55%;"><span class="title_key">জাত</span> <span class="title_value" style="width: 77%;">
                        {{ $i18n.locale==='en' ? datas.seed_class_name : datas.seed_class_name_bn }}
                        </span></p>
                      </div>
                      <p class="title">
                        <span class="title_key">লট নম্বর</span>
                        <span class="title_value" style="width: 80%;">{{$n(datas.lot_number)}}</span>
                      </p>
                      <p class="title">
                        <span class="title_key">প্রত্যয়নপত্র ইস্যুর তারিখ</span>
                        <span class="title_value" style="width: 48%;">{{datas.testing_certification_date | dateFormat}}</span>
                      </p>
                      <p class="title">
                        <span class="title_key">বৈধতার মেয়াদ</span>
                        <span class="title_value" style="width: 67%;">
                          {{ datas.validity_period ==='' ? $i18n.locale==='en' ? '12 Month' : '১২ মাস' : datas.validity_period }}
                        </span>
                      </p>
                      <p class="title">
                        <span class="title_key">বীজের নীট ওজন</span>
                        <span class="title_value" style="width: 62%;">{{ $n(datas.net_weight_seed) }}</span>
                      </p>
                      <p class="title">
                        <span class="title_key">বীজ উৎপাদকের নাম ও ঠিকানা</span>
                        <span class="title_value" style="width: 100%;display: initial;">
                        {{ divName(datas.a_division_id) }},
                        {{ districtName(datas.a_district_id) }},
                        {{ upazilaName(datas.a_upazilla_id) }},
                        {{ unionName(datas.a_union_id) }},
                        {{ datas.a_post_office_name }},
                        {{ $i18n.locale==='en' ? datas.a_holding_number : datas.a_holding_number_bn }}
                        </span>
                      </p>
                    </div>
                    <div class="tag_footer">
                      <p class="title">কর্তৃপক্ষ</p>
                    </div>
                  </div>
                </b-col>
                <b-col sm="6">
                  <div class="certified_wrapper foundation_wrapper">
                    <div class="tag_header_wrapper">
                      <b-overlay :show="loading">
                        <div class="qCode_wrapper">
                          <div v-if="datas">
                            <vue-qrcode style="height: auto; width: 106px !important; margin-top: -27px; !important" :value="
                                      $t('tag_print.seed_class')+':'+datas.seed_class_name+'\n'+
                                      $t('tag_print.seed_name')+':'+datas.seed_name+'\n'+
                                      $t('tag_print.lot_number')+':'+datas.lot_number+'\n'+
                                      $t('tag_print.validity_period')+':'+datas.validity_period+'\n'+
                                      $t('tag_print.seed_producer_name')+':'+datas.dealer_name_en+'\n'+
                                      $t('tag_print.certificate_issue_date')+':'+datas.testing_certification_date+'\n'
                            "/>
                          </div>
                        </div>
                      </b-overlay>
                      <div class="logo_wrapper" style="justify-content: center;padding: 37px 0;">
                        <div class="title_wrapper">
                          <p class="title_card title_card_found"><span>বীজমান</span></p>
                        </div>
                      </div>
                    </div>
                    <div class="tag_body_wrapper" style="padding: 15px 20px;height: 290px;">
                      <p class="title">
                        <span class="title_key">বিশুদ্ধ বীজ (নূন্যতম ওজন)</span>
                        <span class="title_value" style="margin: 0 10px;width: 23%;"></span>
                        <span class="title_value" style="border-bottom: none;">৯৬.০%</span>
                      </p>
                      <p class="title">
                        <span class="title_key">জড় পদার্থ (সর্বোচ্চ ওজন)</span>
                        <span class="title_value" style="margin: 0 10px;width: 29%;"></span>
                        <span class="title_value" style="border-bottom: none;">৩.০%</span>
                      </p>
                      <p class="title">
                        <span class="title_key">অন্যান্য বীজ (সর্বোচ্চ ওজন)</span>
                        <span class="title_value" style="margin: 0 10px;width: 26%;"></span>
                        <span class="title_value" style="border-bottom: none;">১.০%</span>
                      </p>
                      <p class="title">
                        <span class="title_key" style="margin-right: 0">(ক) অন্য ফসলের বীজ (সর্বোচ্চ সংখ্যা)</span>
                        <span class="title_value" style="margin: 0 2px;width: 3%;"></span>
                        <span class="title_value" style="border-bottom: none;">১০/কেজি</span>
                      </p>
                      <p class="title">
                        <span class="title_key" style="margin-right: 0">(খ) আগাছার বীজ (সর্বোচ্চ সংখ্যা)</span>
                        <span class="title_value" style="margin: 0 2px;width: 12%;"></span>
                        <span class="title_value" style="border-bottom: none;">১০/কেজি</span>
                      </p>
                      <p class="title">
                        <span class="title_key">অংকুরোদগম ক্ষমতা (সর্বনিন্ম)</span>
                        <span class="title_value" style="margin: 0 10px;width: 22%;"></span>
                        <span class="title_value" style="border-bottom: none;">৮০%</span>
                      </p>
                      <p class="title">
                        <span class="title_key">আর্দ্রতা (সর্বোচ্চ)</span>
                        <span class="title_value" style="margin: 0 10px;width: 46%;"></span>
                        <span class="title_value" style="border-bottom: none;">১২.০%</span>
                      </p>
                    </div>
                    <div class="tag_footer" style="padding: 20px 5px;text-align: center;">
                      <p class="title m-0" style="border-bottom: 1px solid #ccc;font-weight: 400;">এ বীজ জাতীয় বীজ বোর্ডের নির্ধারিত মান নিশ্চিত করে।</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import VueQrcode from 'vue-qrcode'
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { tagPrintModalApi } from '../../api/routes'

export default {
    props: ['item'],
    components: {
        VueQrcode
    },
    created () {
        if (this.item.id) {
             this.getData()
        }
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            loading: false,
            qr_code: 'Imtiaz',
            data: [],
            datas: {
                seed_class_id: '',
                seed_name: '',
                validity_period: '',
                net_weight_seed: '',
                lot_number: '',
                certificate_issue_date: '',
                seed_variety_name: '',
                pure_seed_result: '',
                inert_substance_result: '',
                dealer_name_en: '',
                date_of_expiry: '',
                dealer_address_en: '',
                seed_of_other_crops_result: '',
                weed_seed_result: '',
                humidity_test_result: '',
                humidity_test_quality: '',
                hard_seed_quality: '',
                pure_seed_quality: ''
            }
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
      divName (id) {
            const data = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.value === id)
            if (data[0]) {
                if (this.$i18n.locale === 'bn') {
                    return data[0].text_bn
                } else {
                    return data[0].text_en
                }
            } else {
                return ''
            }
        },
        districtName (id) {
            const data = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.value === id)
            if (data[0]) {
                if (this.$i18n.locale === 'bn') {
                    return data[0].text_bn
                } else {
                    return data[0].text_en
                }
            } else {
                return ''
            }
        },
        upazilaName (id) {
            const data = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.value === id)
            if (data[0]) {
                if (this.$i18n.locale === 'bn') {
                    return data[0].text_bn
                } else {
                    return data[0].text_en
                }
            } else {
                return ''
            }
        },
        unionName (id) {
            const data = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.value === id)
            if (data[0]) {
                if (this.$i18n.locale === 'bn') {
                    return data[0].text_bn
                } else {
                    return data[0].text_en
                }
            } else {
                return ''
            }
        },
          getData () {
            this.loading = true
            RestApi.getData(agriResearchServiceBaseUrl, tagPrintModalApi + '/' + this.item.id).then(response => {
                if (response.success) {
                    this.datas = response.data
                }
                // this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                this.loading = false
            })
        }
    }
}

</script>
<style lang="scss" scoped>
    .details {
    h6 {
      color: #121264;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .backendData{
      border-bottom: 2px dotted black;
  }
  .card{
    align-items: center;
    margin: 10px 0;
    color: #121264;

    .card-body {
        border: 1px solid #121264;
    }
    .qr{
        text-align: center;
    }
  }
</style>
