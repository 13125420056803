<template>
  <b-container fluid>
          <b-row>
              <b-col md="12">
                  <iq-card>
                      <template v-slot:headerTitle>
                          <div class="text-center">
                              <h4 class="card-title">{{ $t('tag_print.tag_print') + ' ' + $t('globalTrans.list') }}</h4>
                          </div>
                      </template>
                      <template v-slot:body>
                          <b-overlay :show="loadingState">
                              <b-row>
                                  <b-col md="12" class="table-responsive" v-if="datas">
                                      <b-table class="table" bordered hover :fields="columns" :items="datas" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                          <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(created_at)="data">
                                            {{data.item.created_at | dateFormat}}
                                        </template>
                                        <template v-slot:cell(seed_name)="data">
                                            <span v-for="(info, index) in data.item.seed_infos" :key="index">
                                                {{ $i18n.locale === 'en' ? info.seed_name : info.seed_name_bn}},
                                            </span>
                                        </template>
                                        <template v-slot:cell(seed_variety_name)="data">
                                            <span v-for="(info, index) in data.item.seed_infos" :key="index">
                                                {{ $i18n.locale === 'en' ? info.seed_variety_name : info.seed_variety_name_bn}},
                                            </span>
                                        </template>
                                        <template v-slot:cell(seed_class_name)="data">
                                            <span v-for="(info, index) in data.item.seed_infos" :key="index">
                                                {{ $i18n.locale === 'en' ? info.seed_class_name : info.seed_class_name_bn}},
                                            </span>
                                        </template>
                                        <template v-slot:cell(testing_status)="data">
                                            <span v-if="data.item.testing_status == 'Without'">
                                                {{ $i18n.locale === 'en' ? data.item.testing_status : 'প্রত্যয়ন বহির্ভূত' }}
                                            </span>
                                            <span v-else-if="data.item.testing_status == 'With'">
                                                {{ $i18n.locale === 'en' ? data.item.testing_status : 'প্রত্যয়নের আওতাধীন' }}
                                            </span>
                                            <span v-else>{{ data.item.testing_status }}</span>
                                        </template>
                                        <template v-slot:cell(is_tested)="data">
                                            <span class="badge badge-dark" v-if="data.item.application_status === 10">{{ $i18n.locale == 'en' ? 'Lot Offered' : 'লট অফার' }}</span>
                                            <span class="badge text-dark badge-warning" v-if="data.item.application_status === 11">{{ $i18n.locale == 'en' ? 'Testing Payment Pending' : 'টেস্টিং পেমেন্ট পেন্ডিং' }}</span>
                                            <span class="badge badge-primary" v-if="data.item.application_status === 12">{{ $i18n.locale == 'en' ? 'Testing Payment Paid' : 'টেস্টিং পেমেন্ট পেইড' }}</span>
                                            <span class="badge badge-secondary" v-if="data.item.application_status === 13">{{ $i18n.locale == 'en' ? 'Collection Schedule Confirm' : 'কালেকশন শিডিউল কনফার্ম' }}</span>
                                            <span class="badge badge-dark" v-if="data.item.application_status === 14">{{ $i18n.locale == 'en' ? 'Collected' : 'সংগৃহীত' }}</span>
                                            <span class="badge text-dark badge-info" v-if="data.item.application_status === 15">{{ $i18n.locale == 'en' ? 'Registered for Lab Testing' : 'ল্যাব পরীক্ষার জন্য নিবন্ধিত হয়েছে' }}</span>
                                            <span class="badge badge-dark" v-if="data.item.application_status === 16">{{ $i18n.locale == 'en' ? 'Result Submitted' : 'ফলাফল সাবমিট করা হয়েছে' }}</span>
                                            <span class="badge text-dark badge-success" v-if="data.item.application_status === 17  && data.item.s_c_a_test_result_first.tag_status === 1">{{ $i18n.locale == 'en' ? 'Result and Tag Approved' : 'ফলাফল অনুমোদিত' }}</span>
                                            <span class="badge badge-danger" v-if="data.item.application_status === 17  && data.item.s_c_a_test_result_first.tag_status === 2">{{ $i18n.locale == 'en' ? 'Result and Tag Rejected' : 'ট্যাগ বাতিল' }}</span>
                                            <span class="badge text-dark badge-warning" v-if="data.item.application_status === 18">{{ $i18n.locale == 'en' ? 'Lot Confirmed' : 'লট কনফার্মড' }}</span>
                                            <span class="badge badge-primary" v-if="data.item.application_status === 19">{{ $i18n.locale == 'en' ? 'Tag Payment Paid' : 'ট্যাগ পেমেন্ট' }}</span>
                                        </template>
                                          <template v-slot:cell(action)="data">
                                                        <b-button title="View" v-b-modal.modal-4 variant=" iq-bg-primary" size="sm" @click="view(data.item)"><i class="ri-eye-line m-0"></i></b-button>
                                                        <b-button v-b-modal.modal-5 variant=" iq-bg-success mr-1" size="sm" title="View Details" @click="view(data.item)"><i class="ri-eye-line m-1"></i></b-button>
                                                        <span v-if="data.item.seed_infos[0]"><b-button v-if="data.item.seed_infos[0].seed_class_id == 3" title="Print Certified Seed" v-b-modal.modal-6 variant=" iq-bg-primary" style="margin-left:4px" size="sm" @click="view(data.item)"><i class="ri-printer-fill m-0"></i></b-button></span>
                                                        <span v-if="data.item.seed_infos[0]"><b-button v-if="data.item.seed_infos[0].seed_class_id == 2" title="Print Foundation Seed" v-b-modal.modal-7 variant=" iq-bg-primary" style="margin-left:4px" size="sm" @click="view(data.item)"><i class="ri-printer-fill m-0"></i></b-button></span>
                                                        <span v-if="data.item.seed_infos[0]"><b-button v-if="data.item.seed_infos[0].seed_class_id == 4" title="Print Pre Foundation Seed" v-b-modal.modal-8 variant=" iq-bg-primary" style="margin-left:4px" size="sm" @click="view(data.item)"><i class="ri-printer-fill m-0"></i></b-button></span>
                                                        <span v-if="data.item.seed_infos[0]"><b-button v-if="data.item.seed_infos[0].seed_class_id == 1" title="Print Breeder Seed" v-b-modal.modal-9 variant=" iq-bg-primary" style="margin-left:4px" size="sm" @click="view(data.item)"><i class="ri-printer-fill m-0"></i></b-button></span>
                                          </template>
                                      </b-table>
                                      <b-pagination
                                          v-model="pagination.currentPage"
                                          :per-page="pagination.perPage"
                                          :total-rows="pagination.totalRows"
                                          @input="searchData"
                                      />
                                  </b-col>
                              </b-row>
                          </b-overlay>
                      </template>
                  </iq-card>
              </b-col>
            </b-row>
            <b-modal id="modal-4" size="xl" :title="formTitle1" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <DetailsV  :item="itemDetails"/>
            </b-modal>
            <b-modal id="modal-5" size="xl" :title="formTitle2" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <DetailsVD  :item="itemDetails"/>
            </b-modal>
            <b-modal id="modal-9" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <TagBreeder  :item="itemDetails"/>
            </b-modal>
            <b-modal id="modal-6" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <TagCertified  :item="itemDetails"/>
            </b-modal>
            <b-modal id="modal-7" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <TagFoundation  :item="itemDetails"/>
            </b-modal>
            <b-modal id="modal-8" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <TagPreFoundation  :item="itemDetails"/>
            </b-modal>
  </b-container>
</template>

<script>
import DetailsVD from '../detailsView/Details'
import TagBreeder from './TagBreeder.vue'
import TagCertified from './TagCertified.vue'
import TagFoundation from './TagFoundation.vue'
import TagPreFoundation from './TagPreFoundation.vue'
import DetailsV from './Details.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import {
    testingTagRequestWithoutCetificationSubmit,
    tagPrintApi
} from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
export default {
    components: {
        DetailsV,
        TagBreeder,
        TagCertified,
        TagFoundation,
        TagPreFoundation,
        DetailsVD
    },
    mixins: [ModalBaseMasterList],
    data () {
        return {
            search: {
                name: '',
                org_id: 0,
                office_id: 0
            },
            itemDetails: [],
            application: {},
            datas: [],
            info: {
                user_id: this.$store.state.Auth.authUser.id
            },
            target_id: null
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        formTitle () {
            return this.$t('tag_print.tag_print_details')
        },
        formTitle1 () {
            return this.$t('external_research.test_result_details')
        },
        formTitle2 () {
            return this.$t('certify_form.application_details')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('globalTrans.application_date'), class: 'text-left' },
                { label: this.$t('globalTrans.dealer_name_title'), class: 'text-left' },
                { label: this.$t('globalTrans.seed_name_title'), class: 'text-left' },
                { label: this.$t('globalTrans.variety_name_title'), class: 'text-center' },
                { label: this.$t('globalTrans.class_name_title'), class: 'text-center' },
                { label: this.$t('globalTrans.application_type'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                    keys = [
                        { key: 'index' },
                        { key: 'created_at' },
                        { key: 'applicant_address.applicant_name_bn' },
                        { key: 'seed_name' },
                        { key: 'seed_variety_name' },
                        { key: 'seed_class_name' },
                        { key: 'testing_status' },
                        { key: 'is_tested' },
                        { key: 'action' }
                    ]
                } else {
                    keys = [
                        { key: 'index' },
                        { key: 'created_at' },
                        { key: 'applicant_address.applicant_name' },
                        { key: 'seed_name' },
                        { key: 'seed_variety_name' },
                        { key: 'seed_class_name' },
                        { key: 'testing_status' },
                        { key: 'is_tested' },
                        { key: 'action' }
                    ]
                }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    created () {
        if (this.authUser.role_id === 1) {
        } else if (this.authUser.is_org_admin === 1) {
            this.search.org_id = this.authUser.org_id
        } else if (this.authUser.is_admin === true) {
            this.search.org_id = this.authUser.org_id
            this.search.office_id = this.authUser.office_id
        }
    },
    mounted () {
        core.index()
        this.loadData()
    },
    methods: {
        CheckStatus (data) {
            if (data.is_tested) {
                return this.$t('status_check.completed')
            } else {
                return this.$t('status_check.pending')
            }
        },
        view (item) {
            this.itemDetails = item
        },
       async Apply () {
            const result = await RestApi.getData(agriResearchServiceBaseUrl, testingTagRequestWithoutCetificationSubmit + '/' + this.target_id)
            if (result.success) {
                this.loadData()
            }
        },
        FinalSubmit (item) {
            this.target_id = item.id
                window.vm.$swal({
                    title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
                    showCancelButton: true,
                    confirmButtonText: window.vm.$t('globalTrans.yes'),
                    cancelButtonText: window.vm.$t('globalTrans.no'),
                    focusConfirm: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.Apply()
                    }
                })
        },
        async searchData () {
            this.loadData()
        },
        updateValue () {
            this.listView = true
            this.loadData()
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = Object.assign({}, this.info, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(agriResearchServiceBaseUrl, tagPrintApi, params).then(response => {
                if (response.success) {
                    this.datas = response.data.data
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        CheckRegister (type) {
            return (type === 1) ? this.$t('request_testing_tag_without_field_certification_application.registered_status') : this.$t('request_testing_tag_without_field_certification_application.un-registered_status')
        }
    }
}
</script>
<style scoped>
    .form-control {
        padding: 0.375rem 0.75rem
    }
</style>
